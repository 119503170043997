import React from "react"
import aboutOne from "../../components/App/assets/images/donbosco/pictures/aniversario3.jpg"
import aboutTwo from "../../components/App/assets/images/donbosco/pictures/aniversario1.jpg"
import TextGradient from "../Atoms/TextGradient"
import Flickity from "react-flickity-component"

const Anniversary = props => {
  return (
    <div id="anniversary" className="about-area two ptb-100">
      <div className="container">
        <TextGradient>
          <h1>
            Aniversario 206
            <br />
            de Don Bosco
          </h1>
        </TextGradient>
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="about-img">
              <img src={aboutOne} alt="About" />
              <img src={aboutTwo} alt="About" />
            </div>
          </div>

          <div className="col-lg-6">
            <div className="about-content">
              <Flickity
                className={"carousel"} // default ''
                options={{
                  contain: true,
                  prevNextButtons: false,
                  autoPlay: 7000,
                }}
              >
                <div className="section-title two" style={{ width: "100%" }}>
                  <h2>
                    206 Años de <span>Don Bosco</span>. De la familiaridad nace
                    el afecto
                  </h2>
                  <p>
                    El afecto lleva a la confianza; ésta abre los corazones de
                    los jóvenes… Serán sinceros en todo, estarán dispuestos para
                    todo si se dan cuenta que son amados”, enseñaba San Juan
                    Bosco, Padre y Maestro de la juventud.
                  </p>
                </div>
                <div className="section-title two" style={{ width: "100%" }}>
                  <h2>
                    <span>Juan Melchor Bosco Occhiena</span>. nació un 16 de
                    agosto de 1815
                  </h2>
                  <p>
                    En el caserío deI Becchi, cerca de Morialdo en Castelnuovo
                    (norte de Italia), en una familia muy humilde. Los datos de
                    sus primeros años los cuenta el mismo Santo en su famoso
                    libro autobiográfico “Memorias del Oratorio de San Francisco
                    de Sales”, escrito en obediencia al Beato Papa Pío IX.
                  </p>
                </div>
                <div className="section-title two" style={{ width: "100%" }}>
                  <h2>
                    A los nueve años <span>Don Bosco</span> tuvo un sueño
                    profético
                  </h2>
                  <p>
                    En el que Jesús le presenta a su maestra en la vida, la
                    propia Virgen María, quien le pide que se haga “humilde,
                    fuerte y robusto” y le señala su misión de transformar el
                    corazón de los jóvenes.
                  </p>
                </div>
                <div className="section-title two" style={{ width: "100%" }}>
                  <h2>
                    Siendo <span>sacerdote</span>
                  </h2>
                  <p>
                    Acogió a centenares de muchachos a quienes les ofrece
                    espacios de formación para que lleguen a ser “buenos
                    cristianos y honrados ciudadanos”.
                  </p>
                </div>
              </Flickity>
            </div>
          </div>
        </div>
      </div>

      <div class="custom-shape-divider-bottom-1628781736">
        <svg
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1200 120"
          preserveAspectRatio="none"
        >
          <path
            d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z"
            class="shape-fill"
          ></path>
        </svg>
      </div>
    </div>
  )
}

export default Anniversary
