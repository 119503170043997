import React from "react"
import Layout from "../components/App/layout"
import SEO from "../components/App/seo"
import Navbar from "../components/Index/Navbar"
import About from "../components/Index/About"
import Footer from "../components/Index/Footer"
/* Custom Components */
import HeaderBanner from "../components/Index/HeaderBanner"
import PlatformInfo from "../components/Index/PlatformInfo"
import Anniversary from "../components/Index/Anniversary"

const IndexPage = () => (
  <Layout>
    <Navbar />
    <SEO
      author="Don Bosco Sucre"
      lang="es"
      title="COMUNIDAD EDUCATIVA PASTORAL DON BOSCO SUCRE"
      description="La Unidad Educativa “Don Bosco” es una institución educativa de Iglesia Católica de carácter privado, de propiedad de la Comunidad Salesiana Don Bosco, comprende 2 niveles (primario y secundario), cuya fundación data del año 1.963, estableciendo su funcionamiento en la infraestructura ubicada en calle Loa Nº 882 de propiedad de la Sociedad Salesiana. "
    />
    <HeaderBanner />
    <PlatformInfo />
    <Anniversary />
    <About />
    <Footer />
  </Layout>
)

export default IndexPage
