import styled from "styled-components"

const TextGradient = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  > h1 {
    font-size: 40px;
    font-family: "Montserrat";
    font-weight: 700;
    margin: 40px 0px;
    color: #deb45a;
    background-image: linear-gradient(
      180deg,
      #143384 0%,
      #031543 51%,
      #36c1fe 73%
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
  }
`

export default TextGradient
