import React from "react"

const Footer = () => {
  const currentYear = new Date().getFullYear()
  return (
    <React.Fragment>
      <div className="copyright-area">
        <div className="container">
          <div className="copyright-item">
            <p>
              &copy;{currentYear} All Rights Reserved - <b>Don Bosco</b> Sucre
            </p>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Footer
