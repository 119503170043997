import React, { useState } from "react"
import { Link } from "gatsby"
import gallery1 from "../../components/App/assets/images/donbosco/pictures/donbosco-cole1.jpg"
import gallery2 from "../../components/App/assets/images/donbosco/pictures/donbosco-cole6.jpg"
import gallery3 from "../../components/App/assets/images/donbosco/pictures/donbosco-cole7.jpg"
import gallery4 from "../../components/App/assets/images/donbosco/pictures/donbosco-cole2.jpg"
import gallery5 from "../../components/App/assets/images/donbosco/pictures/donbosco-cole4.jpg"

const About = () => {
  return (
    <div id="about" className="about-area ptb-100 color-bg-secondary">
      <div className="container">
        <div className="about-content">
          <div className="section-title">
            <span className="sub-title">COMUNIDAD EDUCATIVA PASTORAL</span>
            <h2 className="color-title-secondary">Don Bosco Sucre</h2>
            <p className="color-subtitle-secondary">
              La Unidad Educativa “Don Bosco” es una institución educativa de
              Iglesia Católica de carácter privado, de propiedad de la Comunidad
              Salesiana Don Bosco, comprende 2 niveles (primario y secundario),
              cuya fundación data del año 1.963, estableciendo su funcionamiento
              en la infraestructura ubicada en calle Loa Nº 882 de propiedad de
              la Sociedad Salesiana.
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col-sm-6 col-lg-6">
            <div className="row">
              <div className="col-lg-12">
                <div className="gallery-item">
                  <img src={gallery1} alt="Gallery" />
                  <div className="inner">
                    <h3>
                      <Link to="#">FORMACIÓN</Link>
                    </h3>
                    <span>INTEGRAL</span>
                  </div>
                </div>
              </div>

              <div className="col-lg-6">
                <div className="gallery-item">
                  <img src={gallery4} alt="Gallery" />
                  <div className="inner">
                    <h3>
                      <Link to="#">CAPACIDAD</Link>
                    </h3>
                    <span>Y LIDERAZGO</span>
                  </div>
                </div>
              </div>

              <div className="col-lg-6">
                <div className="gallery-item">
                  <img src={gallery5} alt="Gallery" />
                  <div className="inner">
                    <h3>
                      <Link to="#">VOCACIÓN</Link>
                    </h3>
                    <span>DE SERVICIO</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-sm-6 col-lg-6">
            <div className="row">
              <div className="col-lg-6">
                <div className="gallery-item">
                  <img src={gallery2} alt="Gallery" />
                  <div className="inner">
                    <h3>
                      <Link to="#">COMUNIDAD</Link>
                    </h3>
                    <span>SALESIANA</span>
                  </div>
                </div>
              </div>

              <div className="col-lg-6">
                <div className="gallery-item">
                  <img src={gallery3} alt="Gallery" />
                  <div className="inner">
                    <h3>
                      <Link to="#">HONESTIDAD</Link>
                    </h3>
                    <span>Y RESPETO</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default About
