import React from "react"
import styled from "styled-components"
import AnchorLink from "react-anchor-link-smooth-scroll"
import { Link } from "gatsby"
import logoDark from "../../components/App/assets/images/donbosco/logo-dark.png"
import logoWhite from "../../components/App/assets/images/donbosco/logo-white.png"
import ButtonAction from "../../components/Atoms/ButtonAction"
import UseAnimations from "react-useanimations"
import scrollDown from "react-useanimations/lib/scrollDown"

import SliderBanda from "../App/assets/images/donbosco/slider/slider-banda-musica.jpeg"
import SliderFrontis from "../App/assets/images/donbosco/slider/slider-frontis-iglesia.jpeg"
import SliderTemplo from "../App/assets/images/donbosco/slider/slider-templo.jpeg"

import Flickity from "react-flickity-component"

const ScrollIcon = styled(UseAnimations)`
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  bottom: 60px;
  z-index: 2;
`

const ImageItem = styled.div`
  position: relative;
  height: 100vh;
  width: 100%;
  background-image: url(${props => props.image});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`

const LogoContent = styled.div`
  position: absolute;
  z-index: 2;
  @media (max-width: 680px) {
    position: relative;
  }
`

const HeaderBanner = () => {
  return (
    <div id="home" className="banner-area banner--expert">
      {/* <div className="banner-img">
        <img src={logoImg} alt="Image" />
      </div> */}
      <div className="banner--expert-left">
        <Flickity
          className={"carousel carousel-cell"} // default ''
          options={{
            wrapAround: true,
            prevNextButtons: false,
            pauseAutoPlayOnHover: false,
            pageDots: false,
            rightToLeft: true,
            autoPlay: 3500,
          }}
        >
          <ImageItem image={SliderBanda} />
          <ImageItem image={SliderFrontis} />
          <ImageItem image={SliderTemplo} />
        </Flickity>
        <LogoContent>
          <img src={logoDark} alt="Image" className="isDark" />
          <img src={logoWhite} alt="Image" className="isWhite" />
        </LogoContent>
      </div>
      <div className="banner--expert-right">
        <h1>
          <small>
            Comunidad
            <br />
            Educativa Pastoral
          </small>
          <br />
          Don Bosco Sucre
        </h1>
        <br />
        <a href="http://181.115.156.38/donbosco/loginE">
          <ButtonAction actionName="Plataforma" />
        </a>
        <a href="http://181.115.156.38/donbosco">
          <ButtonAction actionName="Profesores" colorBackground="#091A49" />
        </a>
      </div>
      <AnchorLink offset={() => 100} href="#platformInfo">
        <ScrollIcon animation={scrollDown} size="36" />
      </AnchorLink>
      <div class="custom-shape-divider-bottom-1627881508">
        <svg
          dataName="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1200 120"
          preserveAspectRatio="none"
        >
          <path
            d="M598.97 114.72L0 0 0 120 1200 120 1200 0 598.97 114.72z"
            className="shape-fill-smoke"
          ></path>
        </svg>
      </div>
    </div>
  )
}

export default HeaderBanner
