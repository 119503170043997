import React from "react"
import { useRecoilState } from "recoil"
import { Link } from "gatsby"
import { sModalState } from "../../utils/recoil-atoms"
import logo from "../../components/App/assets/images/donbosco/don-bosco-white.png"

const Navbar = () => {
  const [collapsed, setCollapsed] = React.useState(true)
  const [sidebarModal, setSidebarModal] = useRecoilState(sModalState)

  const toggleNavbar = () => {
    setCollapsed(!collapsed)
  }

  const toggleModal = () => {
    setSidebarModal(!sidebarModal)
    // console.log(sidebarModal)
  }

  React.useEffect(() => {
    let elementId = document.getElementById("navbar")
    document.addEventListener("scroll", () => {
      if (window.scrollY > 170) {
        elementId.classList.add("is-sticky")
      } else {
        elementId.classList.remove("is-sticky")
      }
    })
    window.scrollTo(0, 0)
  })

  const classOne = collapsed
    ? "collapse navbar-collapse"
    : "collapse navbar-collapse show"
  const classTwo = collapsed
    ? "navbar-toggler navbar-toggler-right collapsed"
    : "navbar-toggler navbar-toggler-right"

  return (
    <React.Fragment>
      <nav
        id="navbar"
        className="navbar navbar-expand-md navbar-light fixed-top expert--navbar"
      >
        <div className="container">
          <Link to="/" className="navbar-brand">
            <img src={logo} alt="logo" />
          </Link>

          <button
            onClick={toggleNavbar}
            className={classTwo}
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="icon-bar top-bar"></span>
            <span className="icon-bar middle-bar"></span>
            <span className="icon-bar bottom-bar"></span>
          </button>

          <div className={classOne} id="navbarSupportedContent">
            <ul className="navbar-nav">
              <li className="nav-item">
                <Link to="/" className="nav-link" activeClassName="active">
                  Inicio
                </Link>
              </li>
              {/* <li className="nav-item">
                <Link
                  to="/nosotros"
                  className="nav-link"
                  activeClassName="active"
                >
                  Nosotros
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="/primaria"
                  className="nav-link"
                  activeClassName="active"
                >
                  Primaria
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="/secundaria"
                  className="nav-link"
                  activeClassName="active"
                >
                  Secundaria
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="/comunicados"
                  className="nav-link"
                  activeClassName="active"
                >
                  Comunicados
                </Link>
              </li> */}
              <li className="nav-item">
                <a
                  href="http://181.115.156.38/donbosco/loginE"
                  className="nav-link"
                  activeClassName="active"
                >
                  Plataforma
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </React.Fragment>
  )
}

export default Navbar
