import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

const ButtonBase = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 206px;
  height: 48px;
  border-radius: 24px;
  border: 0;
  font-weight: 300;
  font-size: 16px;
  background-color: ${props => props.colorBackground};
  box-shadow: 0 1px 1px 0 rgb(0 0 0 / 14%), 0 2px 1px -1px rgb(0 0 0 / 12%),
    0 1px 3px 0 rgb(0 0 0 / 20%);
  color: ${props => props.colorText};
  cursor: pointer;
  margin-bottom: 8px;
  margin-top: 8px;
  .action--click {
    display: flex;
  }
  .action--icon {
    margin-left: 16px;
  }
`

const ButtonAction = ({ actionName, colorBackground, colorText }) => (
  <ButtonBase colorBackground={colorBackground} colorText={colorText}>
    <div className="action--click">
      <div>{actionName}</div>
      <div className="action--icon">
        <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24">
          <path fill="none" d="M0 0h24v24H0z"></path>
          <path
            fill={colorText}
            d="M15 5l-1.41 1.41L18.17 11H2v2h16.17l-4.59 4.59L15 19l7-7-7-7z"
          ></path>
        </svg>
      </div>
    </div>
  </ButtonBase>
)

ButtonAction.propTypes = {
  actionName: PropTypes.string,
  colorBackground: PropTypes.string,
  colorText: PropTypes.string,
}

ButtonAction.defaultProps = {
  actionName: "Click",
  colorBackground: "#0091ff",
  colorText: "#fff",
}

export default ButtonAction
