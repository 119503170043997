import React from "react"
import { Link } from "gatsby"

const PlatformInfo = () => {
  return (
    <div id="platformInfo" className="what-area pt-100 pb-70 color-bg-smoke">
      <div className="common-right-text">
        <span>ACCESO</span>
      </div>

      <div className="container">
        <div className="section-title">
          <span className="sub-title">PLATAFORMA</span>
          <h2>Accesos directos.</h2>
          <p>
            En esta sección estan los accesos rápidos a la plataforma para los
            estudiantes, profesores y material disponible.
          </p>
        </div>

        <div className="row">
          <div className="col-sm-6 col-lg-6">
            <div className="what-item">
              <i className="flaticon-global icon"></i>
              <h3>
                <a href="http://181.115.156.38/donbosco/loginE">Plataforma</a>
              </h3>
              <p>Plataforma de interacción para los estudiantes.</p>

              <a
                href="http://181.115.156.38/donbosco/loginE"
                className="what-btn"
              >
                Ingresar <i className="flaticon-right-arrow"></i>
              </a>
            </div>
          </div>

          <div className="col-sm-6 col-lg-6">
            <div className="what-item">
              <i className="flaticon-branding icon"></i>
              <h3>
                <a href="http://181.115.156.38/donbosco">Profesores</a>
              </h3>
              <p>Plataforma exclusiva para los profesores.</p>

              <a href="http://181.115.156.38/donbosco" className="what-btn">
                Ingresar <i className="flaticon-right-arrow"></i>
              </a>
            </div>
          </div>
          <div className="col-sm-6 col-lg-6">
            <div className="what-item">
              <i className="flaticon-smartphone icon"></i>
              <h3>
                <a
                  href="https://accounts.google.com/AddSession/identifier?service=accountsettings&continue=https%3A%2F%2Fmyaccount.google.com%2F%3Fhl%3Des&hl=es&ec=GAlAwAE&flowName=GlifWebSignIn&flowEntry=AddSession"
                  target="_blank"
                >
                  Correo Institucional
                </a>
              </h3>
              <p>
                Ingresa con tu Correo Institucional <b>@donboscosucre.edu.bo</b>
              </p>

              <a
                href="https://accounts.google.com/AddSession/identifier?service=accountsettings&continue=https%3A%2F%2Fmyaccount.google.com%2F%3Fhl%3Des&hl=es&ec=GAlAwAE&flowName=GlifWebSignIn&flowEntry=AddSession"
                className="what-btn"
              >
                Ingresar <i className="flaticon-right-arrow"></i>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div class="custom-shape-divider-bottom-1627881508">
        <svg
          dataName="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1200 120"
          preserveAspectRatio="none"
        >
          <path
            d="M598.97 114.72L0 0 0 120 1200 120 1200 0 598.97 114.72z"
            className="shape-fill"
          ></path>
        </svg>
      </div>
    </div>
  )
}

export default PlatformInfo
